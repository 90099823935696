import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Upload,
  Modal,
  Space
 } from 'antd';
 import axios from 'axios'
 import { UploadOutlined, } from '@ant-design/icons';
import {request} from '../../../../utils/fetch'
 import moment from 'moment';
 import store from '../../../../store/index'

 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'310px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 7},
    wrapperCol:{ span: 17}
 }
 const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
function PersonList(props) {
    let path = 'company:StaffBase'
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    
    const initSearch = {
        date:[],
       
    }
  
    const [dayValue, setDayValue] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [configList,setConfigList] = useState([])
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [detailObj,setDetailObj] = useState({})
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])    
    const fileProps = {
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept:'.xls, .xlsx',
        beforeUpload: file => {
          return false;
        },

        onChange(info) {
            let fileCon = [...info.fileList];
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1);
            setFileList(fileCon)
        },
      };
    const columns = [
        {
            title: '注册时间',
            dataIndex: 'registeredTime',
            key: 'registeredTime',
            width: 180,
            align:'center',
        }, {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
        },{
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
        }, {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
            align:'center',
        }, 
        { title: '城市', dataIndex: 'cityName', key: 'cityName', align: 'center', width: 120 },
        {
            title: '门店',
            dataIndex: 'storeName',
            key: 'storeName',
            width: 180,
            align:'center',
        },
        {
            title: '人员ID',
            dataIndex: 'eleCourierId',
            key: 'eleCourierId',
            width: 150,
            align:'center',
        },
        {
            title: '来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 150,
            align:'center',
        }, 
        {
            title: '合同签署状态',
            dataIndex: 'signStatusName',
            key: 'signStatusName',
            width: 150,
            align:'center',
        },
        {
            title: '登录状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 150,
            align:'center',
        },  {
            title: '加入状态',
            dataIndex: 'addStatusName',
            key: 'addStatusName',
            width: 150,
            align:'center',
        }, 
        {
            title: '紧急联系人手机号',
            dataIndex: 'emergencyPhone',
            key: 'emergencyPhone',
            width: 150,
            align:'center',
        }, 
        {
            title: '紧急联系人',
            dataIndex: 'emergencyName',
            key: 'emergencyName',
            width: 150,
            align:'center',
        }, 
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm(){
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="时间" name="date" style={{marginLeft:'50px'}}>
                        <RangePicker 
                            showTime
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="登录状态" name="status" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear="true" placeholder="全部">
                            <Option value={'1'}>启用</Option>
                            <Option value={'2'}>停用</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="来源" name="source" style={itemStyle} {...labelStyleObj}>
                        <Select mode='multiple' maxTagCount={1} allowClear="true" placeholder="全部">
                            <Option value={1}>注册</Option>
                            <Option value={2}>导入</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="实名状态" name="certificationStatusList" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear="true" placeholder="全部">
                            <Option value={'RZCG'}>已认证</Option>
                            <Option value={'WRZ'}>未认证</Option>
                            <Option value={'RZSB'}>认证失败</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="合同签署状态" name="signStatusList" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear="true" placeholder="全部">
                            {/* <Option value={'WRZ'}>未认证</Option>
                            <Option value={'RZCG'}>认证成功</Option>
                            <Option value={'RZSB'}>认证失败</Option> */}
                            <Option value={'DDYH'}>等待用户签署</Option>
                            <Option value={'DDGS'}>等待公司签署</Option>
                            <Option value={'WC'}>双方完成签署</Option>
                            <Option value={'YHSB'}>用户签署失败</Option>
                            <Option value={'GSSB'}>公司签署失败</Option>
                        </Select>
                    </FormItem>
                    {/* <FormItem  label="办证状态" name="transactStatusList"  style={itemStyle} {...labelStyleObj}>
                        <Select mode="multiple" allowClear>
                            <Option value="BZDS">视频待审</Option>
                            <Option value="DDBL">等待办理</Option>
                            <Option value="BZSHJJ">视频审核拒绝</Option>
                            <Option value="BZSHTG">视频审核通过</Option>
                            <Option value="YBL">已办理</Option>
                            <Option value="ZXDS">注销待审</Option>
                            <Option value="ZXSHTG">注销审核通过</Option>
                            <Option value="ZXSHJJ">注销审核拒绝</Option>
                            <Option value="YZX">已注销</Option>
                        </Select>
                    </FormItem> */}
                    {/* <FormItem name='taxEnterpriseCode' label="结算通道" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                        {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem> */}
                    <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                        <Input placeholder="姓名丨身份证号丨手机号 | 所在城市" />
                    </FormItem>
                </Row>
                <div style={{display: 'flex', justifyContent: 'flex-end'}} >
                    <div span={24} md={24} lg={24}>
                        <FormItem style={{ float: 'right', whiteSpace: 'nowrap', marginBottom: '0' }} >
                            <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                            <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                            {store.getState().userReducer.permissions.indexOf(`${path}:importStaff`)>-1&&<Button 
                                type="primary"
                                style={{marginLeft:'10px'}}
                                onClick={() => {
                                    setFileList([])
                                    form2.resetFields()
                                    setVisible2(true)
                                }}
                            >
                            导入
                            </Button>}
                        </FormItem>
                    </div>
                </div>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').startOf('day'),
                endDate = moment();
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD HH:mm:ss')
                endTime = formObj.date[1].format('YYYY-MM-DD HH:mm:ss')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime:beginTime,
                endTime:endTime,
                source:formObj.source,
                certificationStatusList:formObj.certificationStatusList,
                signStatusList:formObj.signStatusList,
                status:formObj.status,
                transactStatusList:formObj.transactStatusList,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
                keyword:formObj.keyword,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            console.log(searchObj)
            let result = await request.get('/company/staffBase/list',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        async serviceConfig(){
            let result = await request.get('/company/companyBase/serviceConfig')
            setConfigList(result)
            //    setTableData(result.list)
        },
 
        handlePass(record,isRemove){
            setVisible1(true)
            setDetailObj({
                name:record.name,
                idNumber:record.idNumber,
                phone:record.phone,
                isRemove:isRemove,
            })
        },
        handleOk(){
            setLoading(true)
            request.post('/company/staffBase/updateAddStatus',{
                idNumber:detailObj.idNumber, 
                addStatus:detailObj.isRemove?0:1
            }).then(res => {
                setVisible1(false);
                setLoading(false);
                message.success(res.msg)
                methodsFn.handleSearch()
                
            }).catch(err => setLoading(false))
        },
      
        async handleUp(){
            let values = await form2.validateFields()
            let data = new FormData(); //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            // let file = fileList[0].originFileObj

            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)
            
            axios({
                method: 'post',
                url: `/hire/company/staffBase/importStaff`,
                data: data,
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then(res => {
                  if(res.data.success){
                    setVisible2(false)
                    message.success(res.data.retMsg)
                    methodsFn.handleSearch()
                   
                  }else{
                    message.error(res.data.retMsg)
                  }
               
              }).catch(err => {
                console.log(err)
              })

        },
        handleExport(){
            request.downExport('/company/staffBase/export',methodsFn.getsearchObj())
        },
        // 获取所有结算通道
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        }
    }
    
    useEffect(() => {
        methodsFn.serviceConfig()
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content' }}
            columns={columns} 
            rowKey={(record) => record.idNumber}
            dataSource={tableData} 
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
            <Modal
                forceRender
                title='提示框'
                visible={visible1}
                onOk={methodsFn.handleOk}
                confirmLoading={loading}
                onCancel={() => {setVisible1(false)}}
            >
                <Row>
                    <Space align="center" size='large'>
                        <span style={{width:'60px',display: 'inline-block'}}>姓名:</span>
                        <span>{detailObj.name}</span>
                    </Space>
                </Row>
                <Row>
                    <Space align="center" size='large'>
                        <span style={{width:'60px',display: 'inline-block'}}>身份证号:</span>
                        <span>{detailObj.idNumber}</span>
                    </Space>
                </Row>
                <Row>
                    <Space align="center" size='large'>
                        <span style={{width:'60px',display: 'inline-block'}}>手机号:</span>
                        <span>{detailObj.phone}</span>
                    </Space>
                </Row>
                <Row>
                    确定此人{detailObj.isRemove?'移出':'加入'}本企业么？
                </Row>
            </Modal>
            <Modal
                title='导入'
                visible={visible2}
                onOk={methodsFn.handleUp}
                onCancel={() => {setVisible2(false)}}
                
            >
                <Form form={form2} {...formItemLayout} initialValues={{ }} >
                    <Form.Item label="温馨提示" style={{ marginTop: '-20px', marginBottom: '18px',alignItems: 'start' }} >
                        <div style={{ textAlign: 'left',lineHeight: '32px'}}>
                            <div>同一文件最多支持1000条，身份证号不能重复上传</div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="上传表格"
                        name="ffile"
                        rules={[{ required: true, message: '上传文件' }]}
                    >
                        <Upload {...fileProps}>
                            <Button icon={<UploadOutlined />}>点击上传</Button>
                            <Button type="link" onClick={() => {
                                    var loadUrl =
                                    `/hire/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_YHDR`
                                    window.location.href = loadUrl
                                }}>下载模板</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
         
        </div>
    )
}

export default PersonList
